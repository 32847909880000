import { useEffect } from "react";
import { change } from "redux-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { pickerActions } from "redux/slices/picker/pickerSlice";

export default function UpdatePickerForm({ id }: any) {

  const form = "AddPickerForm";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
	const pickers = useAppSelector((state) => state.picker.pickers);
	const picker = useAppSelector((state) => state.picker.picker);

	console.log("picker", picker)
	

	useEffect(() => {
	  console.log("iddddd", id)
		pickers?.forEach((data) => {
			if (id !== data._id) return
			dispatch(pickerActions.setPicker(data.name))
		
	   
   })
	}, [id, dispatch, pickers]);
	
	useEffect(() => {
    if (!pickers || pickers.length ===0) return;
    dispatch(change(form, "name", picker));
  }, [navigate, dispatch, pickers, picker]);

 

  return null;
}
