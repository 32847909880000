import InputRedux from "components/molecules/InputRedux";
import { required } from "utils/validate.util";
import { ReduxFormField } from "components/molecules/ReduxFormFields";

export { default } from "./UpdatePickerForm";

export const fields: ReduxFormField[] = [
  {
    name: "name",
    label: "Picker Name",
    component: InputRedux,
    validate: [required],
  },
];
