import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PickerState } from ".";

const initialState: PickerState = {
  loading: true,
  pickers: [],
  picker: "",
};

export const pickerSlice = createSlice({
  name: "picker",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setPickers: (state, action) => {
      state.pickers = action.payload;
    },
    setPicker: (state, action) => {
      state.picker = action.payload;
    },
    filterPicker: (state, action) => {
      const id = action.payload;
      state.pickers = state.pickers.filter(({ _id }) => _id !== id);
    },
  },
});

export const pickerReducer = pickerSlice.reducer;
export const pickerActions = pickerSlice.actions;
