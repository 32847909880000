import { useEffect } from "react";
import { useAppSelector } from "redux/hooks";
import ZoneService from "services/zone.service";
import NoResult from "components/templates/NoResult";
import ZoneLoader from "components/templates/ZoneLoader";
import {
	StyledTableCell,
	StyledTableRow,
} from "components/templates/Tables";
import {
	Table,
	TableRow,
	TableBody,
	TableHead,
	TableContainer,
} from "@mui/material";

export default function StatisticsList() {
	const { past, today, future, loading } = useAppSelector(
		(state) => state.zone.statistics
	);

	useEffect(() => {
		ZoneService.getStatistics();
	}, []);

	return (
		<>
			{today.length + past.length + future.length === 0 && loading ? (
				Array(3)
					.fill(1)
					.map((_rows, index) => <ZoneLoader key={index} />)
			) : (
				<>
					<Stat heading="Future" data={future} />
					<Stat heading="Today" data={today} />
					<Stat heading="Past" data={past} />
				</>
			)}
		</>
	);
}

const Stat = ({ data, heading }: { data: any[]; heading: string }) => {
	return (
		<>
			<h2 style={{ marginTop: 0, marginBottom: "6px" }}>{heading}</h2>
			<div
				style={{
					background: "#ffffff",
					marginBottom: "20px",
					padding: "16px",
				}}
			>
				<TableContainer>
					<Table
						aria-label="customized table"
						sx={{
							minWidth: "100%",
							borderBottomWidth: "0px",
						}}
					>
						{data.length === 0 ? (
							<NoResult
								message={`There are no dates available for ${heading}`}
							/>
						) : (
							<>
								<TableHead>
									<TableRow>
										<StyledTableCell
											sx={{ padding: "6px 10px !important" }}
										>
											Date
										</StyledTableCell>
										{data?.[0]?.intervals.map(
											({ interval }: any, i: number) => (
												<StyledTableCell
													key={i}
													align="center"
													sx={{ padding: "6px 10px !important" }}
												>
													{interval}
												</StyledTableCell>
											)
										)}
									</TableRow>
								</TableHead>
								<TableBody>
									{data.map(({ date, intervals }: any, i: number) => (
										<StyledTableRow key={i}>
											<StyledTableCell
												sx={{ padding: "6px 10px !important" }}
											>
												{date}
											</StyledTableCell>
											{intervals.map(({ orders }: any, j: number) => (
												<StyledTableCell
													key={j}
													align="center"
													sx={{ padding: "6px 10px !important" }}
												>
													{orders}
												</StyledTableCell>
											))}
										</StyledTableRow>
									))}
								</TableBody>
							</>
						)}
					</Table>
				</TableContainer>
			</div>
		</>
	);
};
