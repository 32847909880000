import SlotHolidayList from "./SlotHolidayList";
import { Container } from "@mui/material";
import AddSlotHoliday from "./AddSlotHoliday";
export default function SlotHoliday() {
	return (
		<div>
			<Container maxWidth="lg">
				<AddSlotHoliday />
				<SlotHolidayList />
			</Container>
		</div>
	);
}
