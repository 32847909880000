import { MyFormProps } from ".";
import Button from "components/atoms/Button";
import { InjectedFormProps, reduxForm } from "redux-form";
import ReduxFormFields from "components/molecules/ReduxFormFields";

const MyForm = ({
  myFields,
  handleSubmit,
  onClickReset,
  onClickExport,
  submitBtnText = "Submit",
}: MyFormProps & InjectedFormProps<{}, MyFormProps>) => {
  return (
    <form onSubmit={handleSubmit}>
      <ReduxFormFields fields={myFields} />
      <br />
      {onClickReset && (
        <Button
          type="reset"
          variant="outlined"
          onClick={onClickReset}
          sx={{ marginRight: "10px" }}
        >
          Reset
        </Button>
      )}
      <Button variant="contained" type="submit">
        {submitBtnText}
      </Button>
      {onClickExport && (
        <Button
          type="button"
          color="success"
          variant="contained"
          onClick={onClickExport}
          sx={{ marginLeft: "10px" }}
        >
          Export
        </Button>
      )}
    </form>
  );
};

export default reduxForm<{}, MyFormProps>({ form: "MyForm" })(MyForm);
