import { Skeleton, Stack, Table, TableBody } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../Tables";

export default function ZoneLoader() {
	return (
		<div style={{ padding: "20px", background: "#ffffff" }}>
			<Skeleton
				animation="wave"
				width={410}
				sx={{ marginBottom: "10px" }}
			/>
			<Stack direction="row" spacing={2}>
				<Skeleton animation="wave" width={110} height={34} />
				<Skeleton animation="wave" width={110} height={34} />
				<Skeleton animation="wave" width={110} height={34} />
				<Skeleton animation="wave" width={110} height={34} />
				<Skeleton animation="wave" width={110} height={34} />
			</Stack>
			<br />
			<Table>
				<TableBody>
					{Array(6)
						.fill(1)
						.map((_rows, index) => (
							<StyledTableRow key={index}>
								{Array(8)
									.fill(1)
									.map((_coloumn, j) => (
										<StyledTableCell key={j}>
											<Skeleton animation="wave" />
										</StyledTableCell>
									))}
							</StyledTableRow>
						))}
				</TableBody>
			</Table>
		</div>
	);
}
