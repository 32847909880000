import AddSlot from "pages/AddSlot";
import { lazy } from "react";
import { MODAL } from "redux/slices/modal";
import ChangeDispatchForm from "./ChangeDispatchForm";
import CutoffTimeForm from "./CutoffTimeForm";
import CutoffTimeFormCourier from "./CutoffTimeFormCourier";
import UpdatePickerForm from "./UpdatePickerForm";
import AddHolidayFormCourier from "./AddHolidayFormCourier/AddHolidayFormCourier";

const AddPostalCode = lazy(() => import("./AddPostalCode"));
const ConfirmationForm = lazy(() => import("./ConfirmationForm"));
const DeleteHolidayForm = lazy(() => import("./DeleteHolidayForm"));
const SlotOrderLimitForm = lazy(() => import("./SlotOrderLimitForm"));

export { default } from "./AppModal";

export type ModalMapper = {
  [key in MODAL]: "" | JSX.Element;
};

export const modalMapper: ModalMapper = {
  POSTALCODE_FORM: <AddPostalCode />,
  CONFIRMATION_FORM: <ConfirmationForm />,
  DELETE_HOLIDAY_FORM: <DeleteHolidayForm />,
  SLOT_ORDER_LIMIT_FORM: <SlotOrderLimitForm />,
  CUT_OFF_TIME_FORM: <CutoffTimeForm />,
  DISPATCH_SLOT: <ChangeDispatchForm />,
  UPDATE_PICKER_FORM: <UpdatePickerForm />,
  PERIOD_SLOT: <AddSlot />,
  CUT_OFF_TIME_FORM_COURIER: <CutoffTimeFormCourier />,
  ADD_HOLIDAY_FORM_COURIER: <AddHolidayFormCourier />,
};
