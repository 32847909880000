import { fields } from ".";
import { change } from "redux-form";
import ZoneService from "services/zone.service";
import useEffectOnce from "hooks/useEffectOnce";
import MyForm from "components/templates/MyForm";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useNavigate } from "react-router-dom";

export default function CutoffTimeForm() {
  const form = "CutoffTimeForm";
  const dispatch = useAppDispatch();
  const formData = useAppSelector((state) => state.modal.data);
  const navigate = useNavigate();

  useEffectOnce(() => {
    dispatch(change(form, "cutoffTime", formData.cutoffTime));
  });

  const handleSubmit = async (values: any) => {
    let data = { ...values };
    const { zoneId, slotId, validForAllZone } = formData;
    ZoneService.updateCutoffTime(
      {
        zoneId,
        slotId,
        validForAllZone,
        ...data,
      },
      navigate
    );
  };

  return (
    <div>
      <h3>Change Cut-Off Time</h3>
      <MyForm form={form} myFields={fields} onSubmit={handleSubmit} />
    </div>
  );
}
