import { Grid } from "@mui/material";
import { useAppSelector } from "redux/hooks";
import GoBack from "components/atoms/GoBack";
import Container from "@mui/material/Container";
import Banner from "components/templates/Banner";
import MyForm from "components/templates/MyForm";
import UpdateDynamicsForm from "./UpdateDynamicsForm";
import DynamicsService from "services/dynamics.service";
import CircleLoader from "components/atoms/CircleLoader";
import { slotAllowedFields } from ".";

export default function Dynamics() {
	const loading = useAppSelector((state) => state.formLoader.loading);

	const handleSubmit = async (values: any) => {
		let data: any = { ...values };

		data.value = Number(data.value);

		delete data._id;

		DynamicsService.updateDynamics(`${values._id}`, data);
	};

	return (
		<div>
			<Container>
				<GoBack path="/zone" title="Back to Zones" />
				<Banner heading="Dynamics" />
				<div className="form">
					{loading && <CircleLoader />}

					<Grid container spacing={2}>
						<Grid item md={6}>
							<MyForm
								form="slotallowedForm"
								onSubmit={handleSubmit}
								myFields={slotAllowedFields}
							/>
						</Grid>
						{/* <Grid item md={6}>
							<MyForm
								form="maxorderlimitForm"
								onSubmit={handleSubmit}
								myFields={maxOrderLimitFields}
							/>
						</Grid> */}
					</Grid>

					<UpdateDynamicsForm />
				</div>
			</Container>
		</div>
	);
}
