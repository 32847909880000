import { PostalcodeState } from ".";
import { SelectOption } from "components/atoms/Select";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: PostalcodeState = {
	postalcodes: [],
	postalcode: null,
	loading: true,
	postalcodeOptions: [],
};

export const postalcodeSlice = createSlice({
	name: "postalcode",
	initialState,
	reducers: {
		clear: (state) => {
			state.postalcodeOptions = [];
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		addPostalcode: (state, action) => {
			state.postalcodes.unshift(action.payload);
		},
		updatePostalcode: (state, action) => {
			const { id, postalcode } = action.payload;
			state.postalcodes.every(({ _id }, i) => {
				if (id === _id) {
					state.postalcodes[i] = postalcode;
					return false;
				}
				return true;
			});
		},
		setPostalcode: (state, action) => {
			state.postalcode = action.payload;
		},
		setPostalcodes: (state, action) => {
			const postalcodes = action.payload;
			let options: SelectOption[] = [];
			postalcodes.forEach(({ _id, name }: any) =>
				options.push({ value: _id, label: name })
			);
			state.postalcodes = postalcodes;
			state.postalcodeOptions = options;
		},
	},
});

const postalcodeReducer = postalcodeSlice.reducer;

export const postalcodeActions = postalcodeSlice.actions;
export default postalcodeReducer;
