import Button from "components/atoms/Button";
import CircleLoader from "components/atoms/CircleLoader";
import Banner from "components/templates/Banner";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { MODAL, modalActions } from "redux/slices/modal";
import ZoneService from "services/zone.service";
import AddSlotHolidayForm from "./AddSlotHolidayForm";

export default function AddSlotHoliday() {
  const loading = useAppSelector((state) => state.formLoader.loading);
  const dispatch = useAppDispatch();
  const handleSubmit = async (values: any) => {
    let data = { ...values };

    data.validForAllZone = true;
    data.holidays = [moment(data.holidays.date).format("YYYY-MM-DD")];

    ZoneService.addSlotHoliday(data);
  };

  return (
    <div>
      <Banner heading="Slots">
        <Button
          variant="contained"
          onClick={() => {
            dispatch(
              modalActions.openModal({
                width: "500px",
                type: MODAL.PERIOD_SLOT,
                data: {},
              })
            );
          }}
        >
          Add Slot
        </Button>
      </Banner>
      <div className="form">
        {loading && <CircleLoader />}
        <AddSlotHolidayForm onSubmit={handleSubmit} />
      </div>
    </div>
  );
}
