import { useEffect } from "react";
import { change } from "redux-form";
import { useNavigate } from "react-router-dom";
import ZoneService from "services/zone.service";
import { zoneActions } from "redux/slices/zone";
import { useAppDispatch, useAppSelector } from "redux/hooks";

export default function UpdateZoneForm({ id }: any) {
	const form = "AddZoneForm";
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const zone = useAppSelector((state) => state.zone.zone);

	useEffect(() => {
		ZoneService.getZone(`${id}`);
		return () => {
			dispatch(zoneActions.setZone(null));
		};
	}, [id, dispatch]);

	useEffect(() => {
		if (!zone || zone?.data === "Not Found") return;
		const { name, is_active } = zone;
		dispatch(change(form, "name", name));
		dispatch(change(form, "is_active", is_active));
	}, [zone, navigate, dispatch]);

	return null;
}
