import InputRedux from "components/molecules/InputRedux";
import { number } from "utils/validate.util";
import { ReduxFormField } from "components/molecules/ReduxFormFields";

export { default } from "./CutoffTimeForm";

export const fields: ReduxFormField[] = [
  {
    name: "cutoffTime",
    label: "cut-off time",
    component: InputRedux,
    validate: [number],
  },
];
