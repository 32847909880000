import { useEffect } from "react";
import Button from "components/atoms/Button";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import {
	StyledTableCell,
	StyledTableRow,
} from "components/templates/Tables";
import {
	Table,
	TableRow,
	TableBody,
	TableHead,
	TableContainer,
} from "@mui/material";
import { MODAL, modalActions } from "redux/slices/modal";
import PickerService from "services/picker.service";
import { useNavigate } from "react-router-dom";
import SubscriptionService from "services/subscription.service";
import axios from "axios";


const picker = [
	{
		name:"picker",
	},
	{
		name:"picker",
	},
	{
		name:"picker",
	},
	{
		name:"picker",
	},
	{
		name:"picker",
	},
]



export default function PickerList() {
  const pickers = useAppSelector((state)=>state.picker.pickers)
  const loading = useAppSelector((state) => state.picker.loading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate()

  console.log("our picker data",pickers)

  useEffect(() => {

PickerService.getAllPickers()

  }, []);

    // useEffect(() => {
    //   axios({
    //     url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=31.493704,74.417252&key=AIzaSyDnO5gdUDI1we0x9noUejLSHlM4FKGPr9o`,
    //     method: "GET",
    //   }).then((response) => {});
    // }, []);

  return (
    <TableContainer>
      <Table
        aria-label="customized table"
        sx={{
          minWidth: "100%",
          borderSpacing: "0 10px",
          borderBottomWidth: "0px",
          // borderCollapse: "separate",
        }}>
        <TableLoadingWrapper
          coloumns={2}
          loading={loading}
          length={pickers?.length}
          message="No Pickers are available">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ paddingBottom: "15px !important" }}>
                Name
              </StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pickers?.map((data: any, i: number) => (
              <StyledTableRow key={i + 1}>
                <StyledTableCell style={{ padding: "10px 16px" }}>
                  {data.name}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Button
                    onClick={() => {
                      dispatch(
                        modalActions.openModal({
                          width: "500px",
                          type: MODAL.UPDATE_PICKER_FORM,
                          data: {
                            pickerId: data._id,
                          },
                        })
                      );
                    }}
                    // onClick={() => navigate(`/update-picker/${data._id}`)}
                    color="secondary"
                    size="small"
                    variant="text">
                    Edit
                  </Button>
                  <Button
                    onClick={() => {
                      SubscriptionService.subscribe(() => {
                        PickerService.deletePicker(data._id);
                      });
                      dispatch(
                        modalActions.openModal({
                          width: "500px",
                          type: MODAL.CONFIRMATION_FORM,
                          data: {
                            heading: "Delete Picker",
                            message:
                              "Do you really want to delete this Picker?",
                          },
                        })
                      );
                    }}
                    size="small"
                    variant="text">
                    Delete
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </TableLoadingWrapper>
      </Table>
    </TableContainer>
  );
}
