import AddPicker from "pages/AddPicker";
import AddZone from "pages/AddZone/AddZone";
import Courier from "pages/Courier/Courier";
import Delivery from "pages/Delivery/Delivery";
import Dynamics from "pages/Dynamics/Dynamics";
import Holiday from "pages/Holiday/Holiday";
import Picker from "pages/Picker";
import SlotHoliday from "pages/SlotHoliday/SlotHoliday";
import Statistics from "pages/Statistics/Statistics";
import Zone from "pages/Zone/Zone";
import { lazy } from "react";

const Login = lazy(() => import("pages/Login"));
export { default } from "./AppRoutes";

export interface IRoute {
  path: string;
  element: JSX.Element;
}

export const public_routes: IRoute[] = [{ path: "/", element: <Login /> }];

export const private_routes: IRoute[] = [
  { path: "/zone", element: <Zone /> },
  // { path: "/courier", element: <Courier /> },
  { path: "/picker", element: <Picker /> },
  { path: "/add-picker", element: <AddPicker /> },
  { path: "/update-picker/:id", element: <AddPicker /> },
  { path: "/scheduling", element: <Delivery /> },
  { path: "/add-zone", element: <AddZone /> },
  { path: "/update-zone/:id", element: <AddZone /> },

  { path: "/holiday", element: <Holiday /> },
  { path: "/slots", element: <SlotHoliday /> },
  { path: "/dynamics", element: <Dynamics /> },
  { path: "/statistics", element: <Statistics /> },
];
