import { IStatistics } from "./index";
import { ZoneState } from ".";
import { SelectOption } from "components/atoms/Select";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ZoneState = {
  zones: [],
  zone: null,
  dynamics: [],
  loading: true,
  zoneOptions: [],
  zonePeriodOptions: [],
  slotOptions: [],
  statistics: {
    past: [],
    today: [],
    future: [],
    loading: true,
  },

};

export const zoneSlice = createSlice({
  name: "zone",
  initialState,
  reducers: {
    clear: (state) => {
      state.zoneOptions = [];
      state.slotOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addZone: (state, action) => {
      state.zones.push(action.payload);
    },
    updateZone: (state, action) => {
      const { id, zone } = action.payload;
      state.zones.every(({ _id }, i) => {
        if (id === _id) {
          state.zones[i] = zone;
          return false;
        }
        return true;
      });
    },
    setZone: (state, action) => {
      state.zone = action.payload;
    },
    setZones: (state, action) => {
      const zones = action.payload;
      let options: SelectOption[] = [];
      let slotOptions: SelectOption[] = [];
      zones.forEach(
        ({ _id, name, deliverySlots: { slots } }: any, i: number) => {
          options.push({ value: _id, label: name });

          if (i === 0)
            slots.forEach(({ _id, period }: any) => {
              slotOptions.push({ value: _id, label: period });
            });
        }
      );
      state.zones = zones;
      state.zoneOptions = options;
      state.slotOptions = slotOptions;
    },
    setDynamics: (state, action) => {
      state.dynamics = action.payload;
    },
    updateDynamics: (
      state,
      action: PayloadAction<{ name: string; value: number }>
    ) => {
      const { name, value } = action.payload;
      state.dynamics.every((dynamic, i) => {
        if (name === dynamic.name) {
          state.dynamics[i].value = value;
          return false;
        }
        return true;
      });
    },
    filterZonePostalCode: (state, action: PayloadAction<number>) => {
      const postalCode = action.payload;
      state.zones.forEach(({ postalCodes }, i) => {
        state.zones[i].postalCodes = postalCodes.filter(
          (el: number) => el !== postalCode
        );
      });
    },
    addHoliday: (state, action) => {
      const { holidays, validForAllZone, zoneId } = action.payload;

      state.zones.every((zone, i) => {
        if (validForAllZone || zoneId === zone._id) {
          if (!zone.holidays.includes(holidays[0]))
            state.zones[i].holidays.push(holidays[0]);

          if (!validForAllZone) return false;
        }

        return true;
      });
    },
    deleteHoliday: (state, action) => {
      const { holidays, validForAllZone, zoneId } = action.payload;

      state.zones.every((zone, i) => {
        if (validForAllZone || zoneId === zone._id) {
          state.zones[i].holidays = state.zones[i].holidays.filter(
            (holiday: number) => holiday !== holidays[0]
          );

          if (!validForAllZone) return false;
        }

        return true;
      });
    },
    addSlotHoliday: (state, action) => {
      const { holidays, slotId } = action.payload;

      if (state.zones[0])
        state.zones[0].deliverySlots.slots.every((slot: any, i: number) => {
          if (slotId === slot._id) {
            if (!slot.holidays.includes(holidays[0]))
              state.zones[0].deliverySlots.slots[i].holidays.unshift(
                holidays[0]
              );

            return false;
          }

          return true;
        });
    },
    deleteSlotHoliday: (state, action) => {
      const { holidays, slotId } = action.payload;

      if (state.zones[0])
        state.zones[0].deliverySlots.slots.every((slot: any, i: number) => {
          if (slotId === slot._id) {
            state.zones[0].deliverySlots.slots[i].holidays =
              slot.holidays.filter(
                (holiday: number) => holiday !== holidays[0]
              );

            return false;
          }

          return true;
        });
    },
    updateSlots: (state, action: PayloadAction<{ deliverySlots: any }>) => {
      const { deliverySlots } = action.payload;

      if (state.zones[0]) state.zones[0].deliverySlots = deliverySlots;
    },
    setStatisticsLoading: (state, action: PayloadAction<boolean>) => {
      state.statistics.loading = action.payload;
    },
    setStatistics: (state, action: PayloadAction<IStatistics>) => {
      const { past = [], today = [], future = [] } = action.payload;

      state.statistics.past = past;
      state.statistics.today = today;
      state.statistics.future = future;
    },
    setZonePeriods: (state, action) => {
      const periods = action.payload;
      let options: SelectOption[] = [];
      periods.forEach((data : any) =>
          options.push({ value: data, label: data })

      );
      state.zonePeriodOptions = options;
  },


  },
});

const zoneReducer = zoneSlice.reducer;

export const zoneActions = zoneSlice.actions;
export default zoneReducer;
