import http from "./http.service";
import Promisable from "./promiseable.service";
import { modalActions } from "redux/slices/modal";
import { NavigateFunction } from "react-router-dom";
import { getAppDispatch } from "utils/dispatch.util";
import { formLoaderActions } from "redux/slices/formLoader";
import { courierActions } from "redux/slices/courier";
import moment from "moment";

const url = "/courier";

const CourierService = {
  getAllCouriers: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(courierActions.setLoading(true));

    http.setJWT();

    dispatch?.(courierActions.setLoading(true));
    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}`)
    );

    if (success) {
      const { couriers } = success.data.data;
      dispatch?.(courierActions.setCouriers(couriers));
    }

    dispatch?.(courierActions.setLoading(false));
    return [success, error];
  },

  addHoliday: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    data.holidays = [moment(data.holidays.date).format("YYYY-MM-DD")];
    console.log({ data });

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/holidays/${data.courierId}`, data)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      CourierService.getAllCouriers();
    }

    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },
  deleteHoliday: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));
    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/holidays/${data.courierId}`, {
        holidays: data.holidays,
      })
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      CourierService.getAllCouriers();
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  updateCutoffTime: async (data: any, navigate: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    data.cutoffTime = moment(data.cutoffTime.date).format("HH:mm");
    console.log({ data });

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/cutoff-time/${data.courierId}`, data)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      CourierService.getAllCouriers();
    }
    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },
};

export default CourierService;
