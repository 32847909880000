import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ZoneService from "services/zone.service";
import { StyledTableCell, StyledTableRow } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Checkbox,
  Stack,
  Fab,
  IconButton,
} from "@mui/material";
import Button from "components/atoms/Button";
import ZoneLoader from "components/templates/ZoneLoader";
import { days } from ".";
import SubscriptionService from "services/subscription.service";
import { MODAL, modalActions } from "redux/slices/modal";
import NoResult from "components/templates/NoResult";
// import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { EditOutlined } from "@mui/icons-material";

export default function ZoneList() {
  const zones = useAppSelector((state) => state.zone.zones);
  const loading = useAppSelector((state) => state.zone.loading);

  useEffect(() => {
    ZoneService.getAllZones();
  }, []);

  return (
    <>
      {zones.length === 0 && loading ? (
        Array(4)
          .fill(1)
          .map((_rows, index) => <ZoneLoader key={index} />)
      ) : (
        <>
          {zones.map((zone) => (
            <Zone key={zone._id} data={zone} />
          ))}
        </>
      )}
    </>
  );
}

const Zone = ({ data }: any) => {
  const dispatch = useAppDispatch();
  const [edit, setEdit] = useState(false);
  const [zone, setZone] = useState<any>(data);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    setZone(data);
    setEdit(false);
    setChanged(false);
  }, [data]);

  const onChange = (e: any, day: string, index: number) => {
    if (!edit || !zone) return;

    const { checked } = e.target;

    let zoneObj = { ...zone };
    let deliverySlots = { ...zoneObj.deliverySlots };
    let slots = [...deliverySlots.slots];
    let slot = { ...slots[index] };
    let days = [...slot.days];

    if (checked) days.push(day);
    else days = days.filter((el: string) => el !== day);

    slot.days = days;
    slots[index] = slot;
    deliverySlots.slots = slots;
    zoneObj.deliverySlots = deliverySlots;

    setChanged(true);
    setZone(zoneObj);
  };

  const onRemovePostalCode = (code: any, index: number) => {
    SubscriptionService.subscribe(() => {
      ZoneService.deleteZonePostalCode(Number(code));
    });

    dispatch(
      modalActions.openModal({
        width: "500px",
        type: MODAL.CONFIRMATION_FORM,
        data: {
          heading: "Remove Postal Code",
          message: "Do you really want to remove postal code?",
        },
      })
    );
  };

  const onSaveChanges = () => {
    console.log({ zone });
    SubscriptionService.subscribe(() => {
      ZoneService.updateZone(zone._id, zone);
    });

    dispatch(
      modalActions.openModal({
        width: "500px",
        type: MODAL.CONFIRMATION_FORM,
        data: {
          heading: "Change Status",
          message: "Do you really want to change status?",
        },
      })
    );
  };

  return (
    <div
      style={{
        background: "#ffffff",
        marginBottom: "20px",
        padding: "16px",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="start">
        <h2 style={{ marginTop: 0 }}>{zone?.name}</h2>
        {zone.deliverySlots.slots.length ? (
          edit ? (
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                style={{border:"1px solid #C93375",color:"#C93375"}}
                color="secondary"
                onClick={() => {
                  setZone(data);
                  setEdit(false);
                  setChanged(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                disabled={!changed}
                onClick={onSaveChanges}
              >
                Save Changes
              </Button>
            </Stack>
          ) : (
            <Button variant="outlined" onClick={() => setEdit(true)}>
              Edit
            </Button>
          )
        ) : (
          ""
        )}
      </Stack>

      <div className="code-tags">
        {zone?.postalCodes.map((code: any, j: number) => (
          <span className="code-tag-item" key={j}>
            {code}
            <IconButton
              size="small"
              color="primary"
              disabled={!edit}
              onClick={() => onRemovePostalCode(code, j)}
            >
              <HighlightOffIcon  style={{color: "inherit !important"}}/>
            </IconButton>
          </span>
        ))}
        <Fab
          size="small"
          color="primary"
          aria-label="add"
          variant="extended"
          disabled={!edit}
          onClick={() =>
            dispatch(
              modalActions.openModal({
                width: "800px",
                type: MODAL.POSTALCODE_FORM,
                data: {
                  id: zone?._id,
                },
              })
            )
          }
        >
          <AddIcon  />
        </Fab>
      </div>

      {/* <div
        style={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          margin: "10px 0",
        }}
        className="cutoff_time"
      >
        <h4 style={{ margin: 0 }} className="heading">
          Cut-off Time : {zone?.cutoffTime}
        </h4>
        <IconButton
          size="small"
          color="secondary"
          onClick={() => {
            dispatch(
              modalActions.openModal({
                width: "500px",
                type: MODAL.CUT_OFF_TIME_FORM,
                data: {
                  zoneId: zone?._id,
                  validForAllZone: false,
                  cutoffTime: zone?.cutoffTime,
                },
              })
            );
          }}
        >
          <EditOutlined />
        </IconButton>
      </div> */}

      <TableContainer>
        <Table
          aria-label="customized table"
          sx={{
            minWidth: "100%",
            borderBottomWidth: "0px",
          }}
        >
          {zone?.deliverySlots.slots.length === 0 ? (
            <NoResult message={"There are no slots available for this zone."}>
              {/* <Button
                variant="contained"
                onClick={() => navigate(`/add-slot/${zone?._id}`)}
              >
                Add Slot
              </Button> */}
            </NoResult>
          ) : (
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Slot</StyledTableCell>
                  <StyledTableCell>Cut-off Time</StyledTableCell>
                  <StyledTableCell>Dispatch At</StyledTableCell>

                  {days.map((day) => (
                    <StyledTableCell
                      key={day}
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {day.substring(0, 3)}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {zone?.deliverySlots.slots.map((slot: any, index: number) => (
                  <StyledTableRow key={slot._id}>
                    <StyledTableCell>{slot.period}</StyledTableCell>
                    <StyledTableCell style={{ position: "relative" }}>
                      {slot.cutoffTime}
                      <IconButton
                        style={{
                          position: "absolute",
                          right: "60px",
                          top: "3px",
                        }}
                        size="small"
                        color="secondary"
                        onClick={() => {
                          dispatch(
                            modalActions.openModal({
                              width: "500px",
                              type: MODAL.CUT_OFF_TIME_FORM,
                              data: {
                                zoneId: zone?._id,
                                slotId: slot?._id,
                                validForAllZone: false,
                                cutoffTime: slot?.cutoffTime,
                              },
                            })
                          );
                        }}
                      >
                        <EditOutlined />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell
                    >
                        {slot.dispatchAt.charAt(0).toUpperCase() +
                          slot.dispatchAt.slice(1)}
                      {/* <IconButton
                        size="small"
                        style={{ marginLeft: "10px" }}
                        color="secondary"
                        onClick={() => {
                          dispatch(
                            modalActions.openModal({
                              width: "500px",
                              type: MODAL.DISPATCH_SLOT,
                              data: {
                                zoneId: zone?._id,
                                slotId: slot?._id,
                                validForAllZone: false,
                                dispatchAt: slot?.dispatchAt,
                              },
                            })
                          );
                        }}
                      >
                        <EditOutlined />
                      </IconButton> */}
                    </StyledTableCell>

                    {days.map((day) => (
                      <StyledTableCell key={day} align="center">
                        <Checkbox
                          disabled={!edit}
                          checked={slot.days.includes(day)}
                          onChange={(e) => onChange(e, day, index)}
                        />
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            </>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};
