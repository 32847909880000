import http from "./http.service";
import Promisable from "./promiseable.service";
import { authActions } from "redux/slices/auth";
import { getAppDispatch } from "utils/dispatch.util";

const AuthService = {
  login: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(authActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`/login`, data)
    );

    if (success) {
      const { admin, token } = success.data.data;

      localStorage.setItem("token", `Bearer ${token}`);
      dispatch?.(authActions.setUser(admin));
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },

  logout: () => {
    const dispatch = getAppDispatch();

    localStorage.removeItem("token");

    dispatch?.(authActions.logout());
    dispatch?.(authActions.setUser(null));
  },
};
export default AuthService;
