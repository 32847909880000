import { fields } from ".";
import { change } from "redux-form";
import ZoneService from "services/zone.service";
import useEffectOnce from "hooks/useEffectOnce";
import MyForm from "components/templates/MyForm";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { pickerActions } from "redux/slices/picker/pickerSlice";
import PickerService from "services/picker.service";


export default function UpdatePickerForm() {
  const form = "UpdatePickerForm";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pickers = useAppSelector((state) => state.picker.pickers);
  const picker = useAppSelector((state) => state.picker.picker);

  const id = useAppSelector((state)=>state.modal?.data?.pickerId)

	useEffect(() => {
    console.log("iddddd", id);
    pickers?.forEach((data) => {
      if (id !== data._id) return;
      dispatch(pickerActions.setPicker(data.name));
    });
  }, [id, dispatch, pickers]);

  useEffect(() => {
    if (!pickers || pickers.length === 0) return;
    dispatch(change(form, "name", picker));
  }, [navigate, dispatch, pickers, picker]);

  const handleSubmit = async (values: any) => {
    let data = { ...values };
    PickerService.updatePicker(`${id}`, data, navigate);
   
  };

  return (
    <div>
      <h3>Update Picker</h3>
      <MyForm form={form} myFields={fields} onSubmit={handleSubmit} />
    </div>
  );
}
