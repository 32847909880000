import "./Header.css";
import { links } from ".";
import { Fragment } from "react";
import logo from "assets/logo.svg";
import { useAppSelector } from "redux/hooks";
import AuthService from "services/auth.service";
import { Container, Grid } from "@mui/material";
import BasicMenu from "components/atoms/BasicMenu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "components/atoms/Button";

export default function Header() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useAppSelector((state) => state.auth.user);

  if (!user || pathname === "/") return <></>;

  return (
    <header>
      <div className="navbar">
        <Container maxWidth="lg">
          <Grid container alignItems="center">
            <Grid item xs={12} lg={2}>
              <Link to="/zone">
                <div className="logo">
                  <img
                    // src="https://spicejunction.s3.eu-central-1.amazonaws.com/assets/logo-long.png"
                    src={
                      "https://spicejunction.s3.eu-central-1.amazonaws.com/assets/logo-long.png"
                    }
                    alt="logo"
                    loading="lazy"
                  />
                </div>
              </Link>
            </Grid>
            <Grid item xs={12} lg={10}>
              <ul>
                {links.map(({ to, text, active, options }, i) => (
                  <Fragment key={i}>
                    {options ? (
                      <li>
                        <BasicMenu
                          avatar={false}
                          color="#FFFFFF"
                          list={options.map(({ value, label }) => ({
                            text: label,
                            onClick: () => navigate(value),
                          }))}
                        >
                          {text}
                        </BasicMenu>
                        logout
                      </li>
                    ) : (
                      <li key={i}>
                        <Link
                          to={to}
                          className={pathname.includes(active) ? "active" : ""}
                        >
                          {text}
                        </Link>
                      </li>
                    )}
                  </Fragment>
                ))}
                <li>
                  <Button
                    onClick={() => AuthService.logout()}
                    variant="contained"
                    size="large"
                  >
                    Logout
                  </Button>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Container>
      </div>
    </header>
  );
}
