export { default, modalActions, modalSlice } from "./modalSlice";

export enum MODAL {
  POSTALCODE_FORM = "POSTALCODE_FORM",
  CONFIRMATION_FORM = "CONFIRMATION_FORM",
  DELETE_HOLIDAY_FORM = "DELETE_HOLIDAY_FORM",
  SLOT_ORDER_LIMIT_FORM = "SLOT_ORDER_LIMIT_FORM",
  CUT_OFF_TIME_FORM = "CUT_OFF_TIME_FORM",
  UPDATE_PICKER_FORM = "UPDATE_PICKER_FORM",
  DISPATCH_SLOT = "DISPATCH_SLOT",
  PERIOD_SLOT = "PERIOD_SLOT",
  CUT_OFF_TIME_FORM_COURIER = "CUT_OFF_TIME_FORM_COURIER",
  ADD_HOLIDAY_FORM_COURIER = "ADD_HOLIDAY_FORM_COURIER",
}

export type ModalType = "" | keyof typeof MODAL;

export interface OpenModalState {
  data: any;
  width: any;
  type: ModalType;
  loading?: boolean;
}

export type ModalState = OpenModalState & {
  open: boolean;
};
