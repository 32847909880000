import http from "./http.service";
import Promisable from "./promiseable.service";
import { zoneActions } from "redux/slices/zone";
import { getAppDispatch } from "utils/dispatch.util";
import { formLoaderActions } from "redux/slices/formLoader";

const url = "/dynamics";
const DynamicsService = {
  updateDynamics: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, data)
    );

    if (success) {
      const { dynamics } = success.data.data;
      dispatch?.(zoneActions.updateDynamics(dynamics));
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  getDynamics: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}`)
    );

    if (success) {
      const { dynamics } = success.data.data;
      dispatch?.(zoneActions.setDynamics(dynamics));
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
};

export default DynamicsService;
