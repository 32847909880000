import ZoneList from "./ZoneList";
import { Container } from "@mui/material";
import { useNavigate } from "react-router";
import Button from "components/atoms/Button";
import Banner from "components/templates/Banner";

export default function Zone() {
	const navigate = useNavigate();

	return (
		<div>
			<Container maxWidth="lg">
				<Banner heading="Zones">
					<Button
						variant="contained"
						onClick={() => navigate("/add-zone")}
					>
						Add Zone
					</Button>
				</Banner>
				<ZoneList />
			</Container>
		</div>
	);
}
