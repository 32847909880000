import { Field, reduxForm } from "redux-form";
import Button from "components/atoms/Button";
import { useAppSelector } from "redux/hooks";
import { Grid } from "@mui/material";
import DatePickerRedux from "components/molecules/DatePickerRedux";
import { required } from "utils/validate.util";
import SelectRedux from "components/molecules/SelectRedux";

function AddSlotHolidayForm({ form, handleSubmit }: any) {
	const slotOptions = useAppSelector((state) => state.zone.slotOptions);

	return (
		<div>
			<form onSubmit={handleSubmit}>
				<Grid container spacing={2}>
					<Grid item md={6}>
						<Field
							name={"holidays"}
							label={"Holiday"}
							validate={[required]}
							component={DatePickerRedux}
						/>
					</Grid>
					<Grid item md={6}>
						<Field
							name={"slotId"}
							label={"Slot"}
							validate={[required]}
							component={SelectRedux}
							options={slotOptions}
						/>
					</Grid>
				</Grid>
				<br />
				<Button variant="contained" type="submit">
					Submit
				</Button>
			</form>
		</div>
	);
}

export default reduxForm({ form: "AddSlotHolidayForm" })(
	AddSlotHolidayForm
);
