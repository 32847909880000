import { date } from "utils/validate.util";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import DatePickerRedux from "components/molecules/DatePickerRedux/DatePickerRedux";

export { default } from "./AddHolidayFormCourier";

export const fields: ReduxFormField[] = [
  {
    name: "holidays",
    label: "Holiday",
    component: DatePickerRedux,
    validate: [date],
  },
];
