import { Container } from "@mui/material";
import { useNavigate } from "react-router";
import Button from "components/atoms/Button";
import Banner from "components/templates/Banner";
import PickerList from "./PickerList";
import AddPicker from "pages/AddPicker";
export default function Picker() {
  const navigate = useNavigate();
  return (
    <div>
      <Container maxWidth="lg">
        <Banner heading="Pickers">
          {/* <Button variant="contained" onClick={() => navigate("/add-picker")}>
            Add Picker
          </Button> */}
        </Banner>

        <AddPicker/>

        <PickerList />
      </Container>
    </div>
  );
}
