import { required } from "utils/validate.util";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import SelectRedux from "components/molecules/SelectRedux";

export { default } from "./ChangeDispatchForm";

export const fields: ReduxFormField[] = [
  {
    name: "dispatchAt",
    label: "Dispatch Slot",
    validate: [required],
    component: SelectRedux,
    SelectProps: {
      options: [
        { value: "morning", label: "Morning" },
        { value: "afternoon", label: "Afternoon" },
        { value: "evening", label: "Evening" },
        { value: "e-commerce", label: "E-Commerce" },
      ],
    },
    cellProps: { lg: 12 },
  },
];
