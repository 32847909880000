import Empty from "assets/empty.png";
import { TableBody } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../Tables";

export default function NoResult({ message, children }: any) {
  return (
    <TableBody>
      <StyledTableRow>
        <StyledTableCell align="center">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={Empty}
              loading="lazy"
              alt="No results"
              style={{ maxWidth: "100px" }}
            />
            <br />
            <h3 style={{ marginTop: 0, marginBottom: "4px" }}>
              Sorry! No results found :&#40;
            </h3>
            <p style={{ marginTop: 0 }}>{message}.</p>
            {children}
          </div>
        </StyledTableCell>
      </StyledTableRow>
    </TableBody>
  );
}
