import { Grid } from "@mui/material";
import { useAppSelector } from "redux/hooks";
import Button from "components/atoms/Button";
import { Field, reduxForm } from "redux-form";
import { required } from "utils/validate.util";
import InputRedux from "components/molecules/InputRedux";
import SelectRedux from "components/molecules/SelectRedux";

function OrderDetailsForm({ form, handleSubmit }: any) {
	const type = useAppSelector((state) => state.form?.[form]?.values?.type);

	return (
		<div className="form">
			<form onSubmit={handleSubmit}>
				<Grid container spacing={2}>
					<Grid item md={6}>
						<Field
							name={"type"}
							label={"Type"}
							validate={[required]}
							component={SelectRedux}
							options={[
								{ label: "Tag ID", value: "tag_id" },
								{ label: "Order ID", value: "order_id" },
							]}
						/>
					</Grid>
					{type ? (
						type === "tag_id" ? (
							<Grid item md={6}>
								<Field
									name={"tag_id"}
									label={"Tag ID"}
									validate={[required]}
									component={InputRedux}
								/>
							</Grid>
						) : (
							<Grid item md={6}>
								<Field
									name={"order_id"}
									label={"Order ID"}
									validate={[required]}
									component={InputRedux}
								/>
							</Grid>
						)
					) : (
						""
					)}
				</Grid>
				<br />
				<Button variant="contained" type="submit">
					Submit
				</Button>
			</form>
		</div>
	);
}

export default reduxForm({ form: "OrderDetailsForm" })(OrderDetailsForm);
