import { SlotState } from ".";
import { SelectOption } from "components/atoms/Select";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: SlotState = {
  slots: [],
  slot: null,
  loading: true,
  slotOptions: [],
};

export const slotSlice = createSlice({
  name: "slot",
  initialState,
  reducers: {
    clear: (state) => {
      state.slotOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addSlot: (state, action) => {
      state.slots.unshift(action.payload);
    },
    updateSlot: (state, action) => {
      const { id, slot } = action.payload;
      state.slots.every(({ _id }, i) => {
        if (id === _id) {
          state.slots[i] = slot;
          return false;
        }
        return true;
      });
    },
    setSlot: (state, action) => {
      state.slot = action.payload;
    },
    setSlots: (state, action) => {
      const slots = action.payload;
      let options: SelectOption[] = [];
      slots.forEach(({ _id, name }: any) =>
        options.push({ value: _id, label: name })
      );
      state.slots = slots;
      state.slotOptions = options;
    },
  },
});

const slotReducer = slotSlice.reducer;

export const slotActions = slotSlice.actions;
export default slotReducer;
