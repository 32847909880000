import { Field, reduxForm } from "redux-form";
import Button from "components/atoms/Button";
import { useAppSelector } from "redux/hooks";
import { Grid } from "@mui/material";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import DatePickerRedux from "components/molecules/DatePickerRedux";
import { required } from "utils/validate.util";
import SelectRedux from "components/molecules/SelectRedux";

function AddHolidayForm({ form, handleSubmit }: any) {
	const zoneOptions = useAppSelector((state) => state.zone.zoneOptions);
	const validForAllZone = useAppSelector(
		(state) => state.form?.[form]?.values?.validForAllZone
	);

	return (
		<div>
			<form onSubmit={handleSubmit}>
				<Grid container spacing={2}>
					<Grid item md={12}>
						<Field
							name={"validForAllZone"}
							label={"For All Zones"}
							component={CheckboxRedux}
						/>
					</Grid>
					<Grid item md={6}>
						<Field
							name={"holidays"}
							label={"Holiday"}
							validate={[required]}
							component={DatePickerRedux}
						/>
					</Grid>
					{!validForAllZone && (
						<Grid item md={6}>
							<Field
								name={"zoneId"}
								label={"Zone"}
								validate={[required]}
								component={SelectRedux}
								options={zoneOptions}
							/>
						</Grid>
					)}
				</Grid>
				<br />
				<Button variant="contained" type="submit">
					Submit
				</Button>
			</form>
		</div>
	);
}

export default reduxForm({ form: "AddHolidayForm" })(AddHolidayForm);
