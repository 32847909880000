import { fields } from ".";
import { change } from "redux-form";
import useEffectOnce from "hooks/useEffectOnce";
import MyForm from "components/templates/MyForm";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CourierService from "services/courier.service";

export default function AddHolidayFormCourier() {
  const form = "AddHolidayFormCourier";
  const dispatch = useAppDispatch();
  const formData = useAppSelector((state) => state.modal.data);

  useEffectOnce(() => {
    dispatch(change(form, "AddHolidayFormCourier", formData.holidays));
  });

  const handleSubmit = async (values: any) => {
    console.log({ values });
    const { courierId } = formData;
    let data = { courierId, ...values };
    CourierService.addHoliday(data);
  };

  return (
    <div>
      <h3>Add Holiday</h3>
      <MyForm form={form} myFields={fields} onSubmit={handleSubmit} />
    </div>
  );
}
