import CircleLoader from "components/atoms/CircleLoader";
import Banner from "components/templates/Banner";
import moment from "moment";
import { useAppSelector } from "redux/hooks";
import HolidayService from "services/holiday.service";
import AddHolidayForm from "./AddHolidayForm";

export default function AddHoliday() {
	const loading = useAppSelector((state) => state.formLoader.loading);

	const handleSubmit = async (values: any) => {
		let data = { ...values };

		data.holidays = [moment(data.holidays.date).format("YYYY-MM-DD")];
		data.validForAllZone = data.validForAllZone
			? data.validForAllZone
			: false;

		HolidayService.addHoliday(data);
	};

	return (
		<div>
			<Banner heading="Holidays" />
			<div className="form">
				{loading && <CircleLoader />}
				<AddHolidayForm onSubmit={handleSubmit} />
			</div>
		</div>
	);
}
