import { Container } from "@mui/material";
import StatisticsList from "./StatisticsList";
import Banner from "components/templates/Banner";

export default function Statistics() {
	return (
		<div>
			<Container maxWidth="lg">
				<Banner heading="Statistics" />
				<StatisticsList />
			</Container>
		</div>
	);
}
