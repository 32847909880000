import ErrorService from "services/error.service";
import SentryService from "services/sentry.service";

const InitializationService = {
	init: () => {
		ErrorService.init();
		SentryService.init();
	},
};

export default InitializationService;
