import HolidayList from "./HolidayList";
import { Container } from "@mui/material";
import AddHoliday from "./AddHoliday";
export default function Holiday() {
  return (
    <div>
      <Container maxWidth="lg">
        <AddHoliday />
        <HolidayList />
      </Container>
    </div>
  );
}
