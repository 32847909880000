import { fields } from ".";
import { change } from "redux-form";
import useEffectOnce from "hooks/useEffectOnce";
import MyForm from "components/templates/MyForm";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useNavigate } from "react-router-dom";
import CourierService from "services/courier.service";
import moment from "moment";

export default function CutoffTimeFormCourier() {
  const form = "CutoffTimeFormCourier";
  const dispatch = useAppDispatch();
  const formData = useAppSelector((state) => state.modal.data);
  const navigate = useNavigate();

  useEffectOnce(() => {
    dispatch(
      change(
        form,
        `cutoffTime.${["date"]}`,
        moment(`${moment().toNow()} ${formData.cutoffTime}`).format()
      )
    );
  });

  const handleSubmit = async (values: any) => {
    console.log({ values });
    let data = { ...values };
    const { courierId } = formData;
    CourierService.updateCutoffTime(
      {
        courierId,
        ...data,
      },
      navigate
    );
  };

  return (
    <div>
      <h3>Change Cut-Off Time</h3>
      <MyForm form={form} myFields={fields} onSubmit={handleSubmit} />
    </div>
  );
}
