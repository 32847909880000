import http from "./http.service";
import Promisable from "./promiseable.service";
import { modalActions } from "redux/slices/modal";
import { NavigateFunction } from "react-router-dom";
import { getAppDispatch } from "utils/dispatch.util";
import { formLoaderActions } from "redux/slices/formLoader";
import { pickerActions } from "redux/slices/picker/pickerSlice";
import { reset } from "redux-form";

const url = "/pickers";

const PickerService = {
  addPicker: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      dispatch?.(reset("AddPickerForm"));

      PickerService.getAllPickers();
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  updatePicker: async (id: any, data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, data)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      PickerService.getAllPickers();
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  deletePicker: async (id: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      dispatch?.(pickerActions.filterPicker(id));
    }

    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },
  getAllPickers: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(pickerActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}`)
    );

    if (success) {
      const { pickers } = success.data.data;
      dispatch?.(pickerActions.setPickers(pickers));
    }

    dispatch?.(pickerActions.setLoading(false));

    return [success, error];
  },
};

export default PickerService;
