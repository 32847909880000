import { createTheme } from "@mui/material";

const Theme = createTheme({
  palette: {
    primary: {
      light: "#EB63A3",
      main: "#C93375",
      dark: "#952357",
    },
    secondary: {
      light: "#000000",
      main: "#000000",
      dark: "#000000",
    },
  },
  typography: {
    fontFamily: '"Inter", sans-serif',
  },
});

export default Theme;
