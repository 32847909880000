import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import authReducer from "./slices/auth";
import zoneReducer from "./slices/zone";
import slotReducer from "./slices/slot";
import courierReducer from "./slices/courier";
import modalReducer from "./slices/modal";
import postalcodeReducer from "./slices/postalCode";
import formLoaderReducer from "./slices/formLoader";
import { pickerReducer } from "./slices/picker/pickerSlice";

const appReducer = combineReducers({
  form: formReducer,
  courier: courierReducer,
  zone: zoneReducer,
  picker: pickerReducer,
  slot: slotReducer,
  auth: authReducer,
  modal: modalReducer,
  postalcode: postalcodeReducer,
  formLoader: formLoaderReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout") state = {};
  return appReducer(state, action);
};

export default rootReducer;
