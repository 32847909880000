import { ReduxFormField } from "components/molecules/ReduxFormFields";
import TimePickerRedux from "components/molecules/TimePickerRedux/TimePickerRedux";

export { default } from "./CutoffTimeFormCourier";

export const fields: ReduxFormField[] = [
  {
    name: "cutoffTime",
    label: "cut-off time",
    component: TimePickerRedux,
  },
];
