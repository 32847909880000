import "./Delivery.css";
import moment from "moment";
import { CalendarPicker } from "@mui/lab";
// import DateService from "utils/date.util";
import { useEffect, useState } from "react";
import Button from "components/atoms/Button";
import CartIcon from "assets/empty-cart.png";
import NoSlotIcon from "assets/calendar.png";
import { Container } from "@mui/material";
import DeliveryService from "services/delivery.service";
import CircleLoader from "components/atoms/CircleLoader";
import {
	DateMap,
	DeliveryProps,
	DeliverySlot,
	Order,
	Schedule,
	Slot,
} from ".";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { log } from "utils/logger.util";
import { CheckCircle } from "@mui/icons-material";
import OrderDetailsForm from "./OrderDetailsForm";
import { change } from "redux-form";
import { useAppDispatch } from "redux/hooks";

const Delivery = ({ privilege }: DeliveryProps) => {
	// const [checked] = useState(true);
	const dispatch = useAppDispatch();
	const [show, setShow] = useState(false);
	const [tag_id, setTagId] = useState("");
	const [order_id, setOrderId] = useState("");
	const [loading, setLoading] = useState(true);
	const [refresh, setRefresh] = useState(false);
	const [dateMap, setDateMap] = useState<DateMap>({});
	const [slot, setSlot] = useState<Slot | null>(null);
	const [order, setOrder] = useState<Order | null>(null);
	const [date, setDate] = useState<Date | string | null>(null);
	const [deliverySlots, setDeliverySlots] = useState<DeliverySlot[]>([]);
	const [prevSchedule, setPrevSchedule] = useState<Schedule | null>(null);
	const [currentDate, setCurrentDate] = useState<Date | string | null>(
		null
	);

	useEffect(() => {
		dispatch(change("OrderDetailsForm", "type", "tag_id"));
	}, [dispatch]);

	useEffect(() => {
		log({
			date,
			slot,
			dateMap,
			currentDate,
			prevSchedule,
		});
	}, [date, slot, dateMap, currentDate, prevSchedule]);

	useEffect(() => {
		setSlot(null);
	}, [date]);

	useEffect(() => {
		let dateMap: DateMap = {};

		deliverySlots.forEach((deliverySlot) => {
			dateMap[deliverySlot.date.split("T")[0]] = deliverySlot;
		});

		setDateMap(dateMap);
		setDate(
			deliverySlots[0]?.date ? deliverySlots[0].date.split("T")[0] : null
		);
	}, [deliverySlots]);

	useEffect(() => {
		const getDeliverySlots = async () => {
			setLoading(true);

			const [success]: any = await DeliveryService.getDeliverySlots(
				`${tag_id}`,
				order_id,
				privilege
			);

			setLoading(false);

			if (success) {
				const {
					order,
					schedule,
					currentDate = "",
					deliverySlots,
				} = success.data.data;

				if (currentDate) setCurrentDate(currentDate.split("T")[0]);

				if (deliverySlots) {
					setOrder(order);
					setDeliverySlots(deliverySlots);

					if (schedule) {
						let found = false;
						let date = schedule.date.split("T")[0];

						for (const deliverySlot of deliverySlots) {
							if (
								moment(deliverySlot.date.split("T")[0]).isSame(
									schedule.date.split("T")[0],
									"day"
								)
							) {
								setShow(false);
								setDate(date);
								setPrevSchedule({ ...schedule, date });
								found = true;
								break;
							}
						}

						if (!found) {
							// setOrder(null);
							setPrevSchedule({ ...schedule, date });
						}
					}
				} else if (schedule) {
					let date = schedule.date.split("T")[0];
					setPrevSchedule({ ...schedule, date });
				}
			}
		};

		if (tag_id || order_id) getDeliverySlots();
		else setLoading(false);
	}, [tag_id, order_id, refresh, privilege]);

	const onSubmit = async () => {
		if (!slot || !date || loading) return;

		let data: any = {
			slotId: slot._id,
			slotDate: moment(date).format("YYYY-MM-DD"),
		};

		if (order_id) data.order_id = order_id;
		else data.tag_id = tag_id;

		setLoading(true);
		const [success]: any = await DeliveryService.setDeliverySlot(
			data,
			privilege
		);
		setLoading(false);

		if (success) setPrevSchedule({ date, interval: slot.interval });
	};

	const handleSubmit = async (values: any) => {
		setTagId("");
		setOrderId("");
		setShow(false);
		setSlot(null);
		setDate(null);
		setDateMap({});
		setOrder(null);
		setLoading(true);
		setCurrentDate(null);
		setDeliverySlots([]);
		setPrevSchedule(null);
		setRefresh((prev) => !prev);

		if (values.type === "tag_id") setTagId(values.tag_id);
		else if (values.type === "order_id") setOrderId(values.order_id);
	};

	return (
		<div className="main">
			{loading && <CircleLoader />}
			<Container maxWidth="sm">
				<OrderDetailsForm onSubmit={handleSubmit} />
				{
					(tag_id || order_id) && (
						<div className="card">
							{order ? (
								// checked && (
								<>
									<p className="order-id">
										Order ID: <b> {order.name}</b>
									</p>
									<h1 style={{ margin: 0, maxWidth: "70%" }}>
										Hi {order.customer?.split(" ")[0]}!
									</h1>

									<p style={{ marginTop: 0 }}>
										Delivery Area: <b>{order.postalCode}</b>
									</p>
									{!show ? (
										prevSchedule ? (
											<>
												<div className="time_heading">Scheduled</div>
												<div className="para">
													Date:{" "}
													{moment(prevSchedule.date).format(
														"dddd, MMMM Do YYYY"
													)}
													<br />
													Slot: {prevSchedule.interval}
												</div>
											</>
										) : (
											<>
												<div className="time_heading">Select Slot</div>
												<div className="para">
													Select the delivery time slot for the order.
												</div>
											</>
										)
									) : (
										""
									)}
									{show ? (
										<div className="time">
											<div className="back" onClick={() => setShow(false)}>
												Back
											</div>
											<div className="time_heading">Select Date</div>
											<div className="para">
												Select the delivery date for the order.
											</div>

											<div style={{ width: "100%" }}>
												<CalendarPicker
													date={
														date
															? new Date(
																	`${moment(date).format(
																		"YYYY-MM-DD"
																	)}T00:00:00.000`
															  )
															: null
													}
													onYearChange={() => setShow(true)}
													onChange={(newDate) => {
														setShow(false);
														setDate(newDate);
													}}
													shouldDisableDate={(day) =>
														dateMap[moment(day).format("YYYY-MM-DD")]
															? false
															: true
													}
												/>
											</div>
										</div>
									) : (
										<>
											{deliverySlots.length > 0 ? (
												<div className="time">
													{/* <div className="time_heading">
												Select the delivery slot for your order
											</div> */}
													<div className="calender">
														{/* {deliverySlots.map(({ date: dDate }, i) => (
										<Button
											key={i}
											sx={{ margin: "5px 5px 5px 0" }}
											onClick={() => setDate(new Date(dDate))}
											variant={
												moment(date).format("YYYY-MM-DD") ===
												moment(dDate).format("YYYY-MM-DD")
													? "contained"
													: "outlined"
											}
										>
											{DateService.getDeliveryDate(dDate,currentDate || undefined)}
										</Button>
									))} */}
													</div>
													<div
														className="calender"
														style={{ justifyContent: "flex-end" }}
													>
														<Button
															variant="contained"
															onClick={() => setShow(true)}
														>
															<CalendarTodayIcon />
															<div className="date_box">
																{date
																	? // DateService.getDeliveryDate(
																	  // 		date,
																	  // 		currentDate || undefined
																	  //   )
																	  moment(date).format("ddd Do")
																	: "Select Date"}
															</div>
															<ArrowForwardIosIcon />
														</Button>
													</div>
													<div className="time_slots">
														{dateMap[
															moment(date).format("YYYY-MM-DD")
														]?.slots.filter(({ isActive }) => isActive)
															.length > 0 ? (
															dateMap[
																moment(date).format("YYYY-MM-DD")
															]?.slots
																.filter(({ isActive }) => isActive)
																.map(({ _id, interval, isActive }) => (
																	<Button
																		key={_id}
																		disabled={!isActive}
																		variant={
																			slot?._id === _id
																				? "contained"
																				: "outlined"
																		}
																		color={
																			prevSchedule &&
																			moment(prevSchedule.date).isSame(
																				date,
																				"day"
																			) &&
																			prevSchedule.interval === interval
																				? "success"
																				: "primary"
																		}
																		onClick={() =>
																			setSlot({ _id, interval, isActive })
																		}
																		endIcon={
																			prevSchedule &&
																			moment(prevSchedule.date).isSame(
																				date,
																				"day"
																			) &&
																			prevSchedule.interval ===
																				interval ? (
																				<CheckCircle />
																			) : (
																				""
																			)
																		}
																	>
																		{interval}
																	</Button>
																))
														) : (
															<div className="delivery-done">
																<div className="delivery-image">
																	<img
																		src={NoSlotIcon}
																		alt="No Slot"
																		style={{ width: "80px" }}
																	/>
																</div>
																<p>
																	Selected date has no slots available.{" "}
																	<br />
																	Please select another date
																</p>
															</div>
														)}
													</div>

													<Button
														disabled={
															!slot ||
															(prevSchedule &&
															moment(prevSchedule.date).isSame(
																date,
																"day"
															) &&
															prevSchedule.interval === slot.interval
																? true
																: false)
														}
														onClick={onSubmit}
														variant="contained"
														sx={{ marginTop: "10px" }}
													>
														Submit
													</Button>
												</div>
											) : (
												<div style={{ margin: "10px 0" }}>
													No slots available.
												</div>
											)}
										</>
									)}
								</>
							) : loading ? (
								""
							) : (
								<div className="delivery-done">
									<div className="delivery-image">
										<img src={CartIcon} alt="Cart" />
									</div>
									<p>
										<b>Order Not Found!</b>
									</p>
								</div>
							)}
						</div>
					)
					// : (
					// 	<div className="delivery-done">
					// 		<div className="delivery-image">
					// 			<img src={InvalidIcon} alt="Delivery" />
					// 		</div>
					// 		<p>
					// 			<b>Invalid order details</b>
					// 		</p>
					// 	</div>
					// )
				}
			</Container>
		</div>
	);
};

export default Delivery;
