import AddSlotForm from "./AddSlotForm";
import ZoneService from "services/zone.service";
import moment from "moment";

export default function AddSlot() {
  const handleSubmit = async (values: any) => {
    let data = { ...values };

    const startTime = moment(data.startTime.date).format("HH:mm");

    data.startTime = startTime;
    data.validForAllZone = true;
    data.isActive = true;
    data.durationInHours = Number(data.durationInHours);
    data.position = Number(data.position);

    ZoneService.addSlot(data);
  };

  return (
    <div>
      <h3>Add Slot</h3>
      <AddSlotForm onSubmit={handleSubmit} />
      {/* </div> */}
    </div>
  );
}
