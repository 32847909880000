import { useEffect } from "react";
import ZoneService from "services/zone.service";
import { IconButton, Stack } from "@mui/material";
import { MODAL, modalActions } from "redux/slices/modal";
import ZoneLoader from "components/templates/ZoneLoader";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SubscriptionService from "services/subscription.service";
import DeleteIcon from '@mui/icons-material/Delete';

export default function SlotHolidayList() {
  const dispatch = useAppDispatch();
  const zones = useAppSelector((state) => state.zone.zones);
  const loading = useAppSelector((state) => state.zone.loading);
  const zone = zones[0];

  useEffect(() => {
    ZoneService.getAllZones();
  }, []);

  const onDeleteSlot = (id:any) => {

  };

  return (
    <>
      {zones.length === 0 && loading ? (
        Array(4)
          .fill(1)
          .map((_rows, index) => <ZoneLoader key={index} />)
      ) : (
        <>
          {zone &&
            zone.deliverySlots.slots.map((slot: any) => (
              <div key={slot._id}>
                {/* {slot.holidays?.length > 0 && ( */}
                <div
                  style={{
                    background: "#ffffff",
                    marginBottom: "20px",
                    padding: "16px",
                    position:"relative"
                  }}
                >
                  <div style={{
                    float:"right",
                    cursor:"pointer"
                  }} 
                  
                  >
                    <DeleteIcon
                    onClick={()=>{
                      SubscriptionService.subscribe(() => {
                        ZoneService.deleteSlot(slot._id)
                      });
                  
                      dispatch(
                        modalActions.openModal({
                          width: "500px",
                          type: MODAL.CONFIRMATION_FORM,
                          data: {
                            heading: "Remove Slot",
                            message: "Do you really want to remove Slot?",
                          },
                        })
                      );
                    }}
                    />
                  </div>

                  <h2 style={{ margin: 0 }}>{slot.period}</h2>
                  <Stack direction="row" alignItems="center">
                    <p style={{ margin: 0 }}>
                      Order Limit: <b>{slot.orderLimit}</b>
                    </p>
                    <h3 style={{ margin: 0 }}>
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => {
                          dispatch(
                            modalActions.openModal({
                              width: "500px",
                              type: MODAL.SLOT_ORDER_LIMIT_FORM,
                              data: {
                                slotId: slot._id,
                                validForAllZone: true,
                                orderLimit: slot.orderLimit,
                              },
                            })
                          );
                        }}
                      >
                        <EditOutlined />
                      </IconButton>
                    </h3>
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <p style={{ margin: 0 }}>
                      Cut of Time: <b>{slot.cutoffTime}</b>
                    </p>
                    <h3 style={{ margin: 0 }}>
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => {
                          dispatch(
                            modalActions.openModal({
                              width: "500px",
                              type: MODAL.CUT_OFF_TIME_FORM,
                              data: {
                                slotId: slot?._id,
                                validForAllZone: true,
                                cutoffTime: slot?.cutoffTime,
                              },
                            })
                          );
                        }}
                      >
                        <EditOutlined />
                      </IconButton>
                    </h3>
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Stack direction="row" alignItems="center">
                      <div>
                        Dispatch At: &nbsp;
                        <b>
                          {slot.dispatchAt.charAt(0).toUpperCase() +
                            slot.dispatchAt.slice(1)}
                        </b>
                      </div>
                      <IconButton
                        size="small"
                        style={{ marginLeft: "10px" }}
                        color="secondary"
                        onClick={() => {
                          dispatch(
                            modalActions.openModal({
                              width: "500px",
                              type: MODAL.DISPATCH_SLOT,
                              data: {
                                zoneId: zone?._id,
                                slotId: slot?._id,
                                validForAllZone: false,
                                dispatchAt: slot?.dispatchAt,
                              },
                            })
                          );
                        }}
                      >
                        <EditOutlined />
                      </IconButton>
                    </Stack>
                  </Stack>

                  <div className="code-tags">
                    {slot?.holidays?.length > 0 && "Holidays: "}&nbsp;
                    {slot.holidays.map((holiday: any, j: number) => (
                      <span className="code-tag-item" key={j}>
                        {holiday}
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => {
                            SubscriptionService.subscribe(() => {
                              ZoneService.deleteSlotHoliday({
                                slotId: slot._id,
                                holidays: [holiday],
                                validForAllZone: true,
                              });
                            });

                            dispatch(
                              modalActions.openModal({
                                width: "500px",
                                type: MODAL.CONFIRMATION_FORM,
                                data: {
                                  heading: "Remove Slot Holiday",
                                  message:
                                    "Do you really want to remove slot holiday?",
                                },
                              })
                            );
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </span>
                    ))}
                  </div>
                </div>
                {/* )} */}
              </div>
            ))}
        </>
      )}
    </>
  );
}
