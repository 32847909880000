import { signNumbers } from "utils/normalize.util";
import InputRedux from "components/molecules/InputRedux";
import { number, required } from "utils/validate.util";
import { ReduxFormField } from "components/molecules/ReduxFormFields";

export { default } from "./Dynamics";

export const slotAllowedFields: ReduxFormField[] = [
	{
		name: "value",
		label: "Cut-Off Time",
		validate: [required, number],
		component: InputRedux,
		normalize: signNumbers,
	},
];

// export const maxOrderLimitFields: ReduxFormField[] = [
// 	{
// 		name: "value",
// 		label: "Max Order Limit",
// 		validate: [required, greaterThan0],
// 		component: InputRedux,
// 		normalize: digits,
// 	},
// ];
