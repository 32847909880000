import { SelectOption } from "components/atoms/Select";
export { default } from "./Header";

export interface ILink {
  to: string;
  text: string;
  active: string;
  options?: SelectOption[];
}

export const links: ILink[] = [
  { to: "/zone", text: "Zones", active: "zone" },
  { to: "/slots", text: "Slots", active: "slot" },
  { to: "/picker", text: "Pickers", active: "picker" },
  // { to: "/courier", text: "Couriers", active: "courier" },
  // { to: "/scheduling", text: "Scheduling", active: "scheduling" },
  // { to: "/zone", text: "Zones", active: "zone" },
  // { to: "/slots", text: "Slots", active: "slot" },
  // { to: "/holiday", text: "Holidays", active: "holiday" },
  // { to: "/dynamics", text: "Dynamics", active: "dynamics" },
  // { to: "/statistics", text: "Statistics", active: "statistics" },
];
