import { change } from "redux-form";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import DynamicsService from "services/dynamics.service";
import { useAppDispatch, useAppSelector } from "redux/hooks";

export default function UpdateDynamicsForm() {
	const isSet = useRef(false);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const dynamics = useAppSelector((state) => state.zone.dynamics);

	useEffect(() => {
		DynamicsService.getDynamics();
	}, [dispatch]);

	useEffect(() => {
		if (isSet.current || dynamics.length === 0) return;

		for (const dynamic of dynamics) {
			if (!["slotallowed", "maxorderlimit"].includes(dynamic.name))
				continue;

			let form = `${dynamic.name}Form`;

			dispatch(change(form, "_id", dynamic._id));
			dispatch(change(form, "name", dynamic.name));
			dispatch(change(form, "value", dynamic.value));
		}

		isSet.current = true;
	}, [dynamics, navigate, dispatch]);

	return null;
}
