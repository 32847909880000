import AddZoneForm from "./AddZoneForm";
import { useAppSelector } from "redux/hooks";
import GoBack from "components/atoms/GoBack";
import UpdateZoneForm from "./UpdateZoneForm";
import Container from "@mui/material/Container";
import ZoneService from "services/zone.service";
import Banner from "components/templates/Banner";
import { useNavigate, useParams } from "react-router-dom";
import CircleLoader from "components/atoms/CircleLoader";

export default function AddZone() {
	const { id } = useParams();
	const navigate = useNavigate();
	const zone = useAppSelector((state) => state.zone.zone);
	const loading = useAppSelector((state) => state.formLoader.loading);

	const handleSubmit = async (values: any) => {
		let data = { ...values };
		// data.is_active = data.is_active ? data.is_active : false;

		if (id) ZoneService.updateZone(`${id}`, data, navigate);
		else ZoneService.addZone(data, navigate);
	};

	return (
		<div>
			<Container>
				<GoBack path="/zone" title="Back to Zones" />
				<Banner heading={`${id ? "Update" : "Add"} Zone`} />
				<div className="form">
					{loading && <CircleLoader />}
					{zone?.data === "Not Found" ? (
						<p>Zone Not Found</p>
					) : (
						<AddZoneForm onSubmit={handleSubmit} />
					)}
					{id && <UpdateZoneForm id={id} />}
				</div>
			</Container>
		</div>
	);
}
