import OptionService from "utils/option.util";

import { required } from "utils/validate.util";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import SelectRedux from "components/molecules/SelectRedux";
import TimePickerRedux from "components/molecules/TimePickerRedux";

export { default } from "./AddSlotForm";

export const fields: ReduxFormField[] = [
  {
    name: "startTime",
    label: "Interval Start Time",
    validate: [required],
    component: TimePickerRedux,
    cellProps: { lg: 12 },
  },
  {
    name: "durationInHours",
    label: "Slot Duration in Hours",
    validate: [required],
    component: SelectRedux,
    SelectProps: {
      // StartIcon: BsStopwatchFill,
      options: OptionService.getOptions(8, 1),
    },
    cellProps: { lg: 12 },
  },
  {
    name: "dispatchAt",
    label: "Dispatch Slot",
    validate: [required],
    component: SelectRedux,
    SelectProps: {
      options: [
        { value: "morning", label: "Morning" },
        { value: "afternoon", label: "Afternoon" },
        { value: "evening", label: "Evening" },
        { value: "e-commerce", label: "E-Commerce" },
      ],
    },
    cellProps: { lg: 12 },
  },
  {
    name: "position",
    label: "Positions",
    validate: [required],
    component: SelectRedux,
    SelectProps: {
      // StartIcon: BsStopwatchFill,
      options: OptionService.getOptions(11, 0),
    },
    cellProps: { lg: 12 },
  },
];
