import { formLoaderActions } from "redux/slices/formLoader";
import { modalActions } from "redux/slices/modal";
import { zoneActions } from "redux/slices/zone";
import { getAppDispatch } from "utils/dispatch.util";
import http from "./http.service";
import Promisable from "./promiseable.service";

const url = "/zones/holidays";

const HolidayService = {
  addHoliday: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, data)
    );

    if (success) dispatch?.(zoneActions.addHoliday(data));

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  deleteHoliday: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}`, data)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      dispatch?.(zoneActions.deleteHoliday(data));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default HolidayService;
