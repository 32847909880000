import http from "./http.service";
import Promisable from "./promiseable.service";

const url = `/schedule`;

const DeliveryService = {
  getDeliverySlots: async (
    tag_id?: string,
    order_id?: string | null,
    privilege?: boolean
  ) => {
    http.setJWT();

    let uri = url;
    if (privilege) uri += "/privilege";
    const query = order_id ? `order_id=${order_id}` : `tag_id=${tag_id}`;

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${uri}?${query}`)
    );

    return [success, error];
  },

  setDeliverySlot: async (data: any, privilege?: boolean) => {
    http.setJWT();

    let uri = url;
    if (privilege) uri += "/privilege";
    const [success, error]: any = await Promisable.asPromise(
      http.post(`${uri}`, data)
    );

    return [success, error];
  },
};

export default DeliveryService;
