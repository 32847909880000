import { required } from "utils/validate.util";
import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";

export { default } from "./AddPickerForm";

export const fields: ReduxFormField[] = [
	// {
	// 	name: "is_active",
	// 	label: "Active",
	// 	component: CheckboxRedux,
	// 	cellProps: { md: 12 },
	// },
	{
		name: "name",
		label: "Picker Name",
		validate: [required],
		component: InputRedux,
		cellProps: { md: 6, lg: 6 },
	},
];
