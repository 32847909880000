import { useEffect } from "react";
import ZoneService from "services/zone.service";
import { IconButton, Stack } from "@mui/material";
import { MODAL, modalActions } from "redux/slices/modal";
import ZoneLoader from "components/templates/ZoneLoader";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function HolidayList() {
	const dispatch = useAppDispatch();
	const zones = useAppSelector((state) => state.zone.zones);
	const loading = useAppSelector((state) => state.zone.loading);

	useEffect(() => {
		ZoneService.getAllZones();
	}, []);

	return (
		<>
			{zones.length === 0 && loading ? (
				Array(4)
					.fill(1)
					.map((_rows, index) => <ZoneLoader key={index} />)
			) : (
				<>
					{zones.map((zone) => (
						<div
							key={zone._id}
							style={{
								background: "#ffffff",
								marginBottom: "20px",
								padding: "16px",
							}}
						>
							<Stack
								direction="row"
								justifyContent="space-between"
								alignItems="start"
							>
								<h2 style={{ marginTop: 0 }}>{zone?.name}</h2>
							</Stack>

							<div className="code-tags">
								{zone?.holidays.map((holiday: any, j: number) => (
									<span className="code-tag-item" key={j}>
										{holiday}
										<IconButton
											size="small"
											color="primary"
											onClick={() =>
												dispatch(
													modalActions.openModal({
														width: "500px",
														type: MODAL.DELETE_HOLIDAY_FORM,
														data: {
															zoneId: zone._id,
															holidays: [holiday],
														},
													})
												)
											}
										>
											<HighlightOffIcon />
										</IconButton>
									</span>
								))}
							</div>
						</div>
					))}
				</>
			)}
		</>
	);
}
