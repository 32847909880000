import { CourierState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: CourierState = {
  couriers: [],
  courier: null,
  loading: true,
  courierOptions: [],
};

export const courierSlice = createSlice({
  name: "courier",
  initialState,
  reducers: {
    clear: (state) => {
      state.courierOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addCourier: (state, action) => {
      state.couriers.push(action.payload);
    },
    updateCourier: (state, action) => {
      const { id, courier } = action.payload;
      state.couriers.every(({ _id }, i) => {
        if (id === _id) {
          state.couriers[i] = courier;
          return false;
        }
        return true;
      });
    },
    setCourier: (state, action) => {
      state.courier = action.payload;
    },
    setCouriers: (state, action) => {
      state.couriers = action.payload;
    },
  },
});

const courierReducer = courierSlice.reducer;

export const courierActions = courierSlice.actions;
export default courierReducer;
