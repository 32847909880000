import http from "./http.service";
import Promisable from "./promiseable.service";
import { zoneActions } from "redux/slices/zone";
import { modalActions } from "redux/slices/modal";
import { NavigateFunction } from "react-router-dom";
import { getAppDispatch } from "utils/dispatch.util";
import { formLoaderActions } from "redux/slices/formLoader";

const url = "/zones";

const ZoneService = {
  addZone: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      const { zone } = success.data.data;
      dispatch?.(zoneActions.addZone(zone));
      navigate?.("/zone");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  updateZone: async (id: string, data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, data)
    );

    if (success) {
      const { zone } = success.data.data;
      dispatch?.(modalActions.closeModal());
      dispatch?.(zoneActions.updateZone({ id, zone }));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  deleteZonePostalCode: async (postalCode: number) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/postal-codes`, {
        data: { postalCodes: [postalCode] },
      })
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      dispatch?.(zoneActions.filterZonePostalCode(postalCode));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  getZone: async (id: string) => {
    const dispatch = getAppDispatch();
    // dispatch?.(loaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const { zone } = success.data.data;
      dispatch?.(zoneActions.setZone(zone));
    } else dispatch?.(zoneActions.setZone({ data: "Not Found" }));

    // dispatch?.(loaderActions.setLoading(false));
    return [success, error];
  },

  getAllZones: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(zoneActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}`)
    );

    if (success) {
      const { zones } = success.data.data;
      dispatch?.(zoneActions.setZones(zones));
    }

    dispatch?.(zoneActions.setLoading(false));
    return [success, error];
  },

  getStatistics: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(zoneActions.setStatisticsLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`/statistics/slot-orders`)
    );

    if (success) {
      const { statistics } = success.data.data;
      dispatch?.(
        zoneActions.setStatistics({
          future: statistics?.future,
          today: statistics?.today,
          past: statistics?.past,
        })
      );
    }

    dispatch?.(zoneActions.setStatisticsLoading(false));
    return [success, error];
  },

  updateSlotOrderLimit: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/order-limit`, data)
    );

    if (success) {
      const deliverySlots = success.data.data;

      dispatch?.(modalActions.closeModal());
      dispatch?.(zoneActions.updateSlots({ deliverySlots }));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  addSlotHoliday: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/slot-holidays`, data)
    );

    if (success) {
      const deliverySlots = success.data.data;
      dispatch?.(zoneActions.updateSlots({ deliverySlots }));
      // dispatch?.(zoneActions.addSlotHoliday(data));
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  addSlot: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/add-slot`, data)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      ZoneService.getAllZones();
    }

    dispatch?.(modalActions.setLoading(false));
    dispatch?.(modalActions.closeModal());

    return [success, error];
  },

  deleteSlotHoliday: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/slot-holidays`, { data })
    );

    if (success) {
      const deliverySlots = success.data.data;

      dispatch?.(modalActions.closeModal());
      dispatch?.(zoneActions.updateSlots({ deliverySlots }));
      // dispatch?.(zoneActions.deleteSlotHoliday(data));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  updateCutoffTime: async (data: any, navigate: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/slot-cutoff-time`, data)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      ZoneService.getAllZones();
    }
    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },
  updateDispatchTime: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/dispatchAt`, data)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      ZoneService.getAllZones();
    }
    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },

  getSlotPeriods: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(zoneActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/time-slots`)
    );

    if (success) {
      const { periods } = success.data.data;
      dispatch?.(zoneActions.setZonePeriods(periods));
      dispatch?.(zoneActions.setLoading(false));
    }

    dispatch?.(zoneActions.setLoading(false));
    return [success, error];
  },
  deleteSlot: async (id: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/remove-slot`, {
        slotId: id,
        validForAllZone: true,
      })
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      ZoneService.getAllZones();

      // dispatch?.(zoneActions.filterZonePostalCode(postalCode));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default ZoneService;
