import { useAppSelector } from "redux/hooks";
import GoBack from "components/atoms/GoBack";
import Container from "@mui/material/Container";
import ZoneService from "services/zone.service";
import Banner from "components/templates/Banner";
import { useNavigate, useParams } from "react-router-dom";
import CircleLoader from "components/atoms/CircleLoader";
import AddPickerForm from "./AddPickerForm";
import UpdatePickerForm from "./UpdatePickerForm";
import PickerService from "services/picker.service";

export default function AddPicker() {
  const { id } = useParams();
  const navigate = useNavigate();
  const zone = useAppSelector((state) => state.zone.zone);
  const loading = useAppSelector((state) => state.formLoader.loading);

  const handleSubmit = async (values: any) => {
    let data = { ...values };
    // data.is_active = data.is_active ? data.is_active : false;

    if (id) PickerService.updatePicker(`${id}`, data, navigate);
    else PickerService.addPicker(data, navigate);
  };

  return (
    <div>
      <>
        {/* <GoBack path="/picker" title="Back to Pickers" /> */}
        {/* <Banner heading={`${id ? "Update" : "Add"} Picker`} /> */}
        <div className="form">
          {loading && <CircleLoader />}
        
            <AddPickerForm onSubmit={handleSubmit} />
        
          {id && <UpdatePickerForm id={id} />}
        </div>
      </>
    </div>
  );
}
